'use strict';
window.dndNextLevel = window.dndNextLevel || {};
dndNextLevel.home = dndNextLevel.home || {};


dndNextLevel.themeOff = ['text-gray-600', 'dark:text-gray-600'];
dndNextLevel.themeOn = ['text-amber-600', 'dark:text-amber-600'];

dndNextLevel.home.home = () => {
    const button = document.getElementById("toggleLegalSections");
    button.addEventListener("change", dndNextLevel.home.toggleLegalSections);
    dndNextLevel.home.licenseToaster();
}

dndNextLevel.home.toggleLegalSections = () => {
    console.log("toggle clicked");
    const legal2024Section = document.getElementById('legal2024');
    const legal2014Section = document.getElementById('legal2014');
    const toggle2024Text = document.getElementById('toggle2024Text');
    const toggle2014Text = document.getElementById('toggle2014Text');

    if (legal2014Section.classList.contains('hidden')) {
        legal2014Section.classList.replace('hidden', 'flex');
        legal2024Section.classList.replace('flex', 'hidden');
        dndNextLevel.home.replaceClasses(toggle2024Text, dndNextLevel.themeOn, dndNextLevel.themeOff);
        dndNextLevel.home.replaceClasses(toggle2014Text, dndNextLevel.themeOff, dndNextLevel.themeOn);
    } else {
        legal2014Section.classList.replace('flex', 'hidden');
        legal2024Section.classList.replace('hidden', 'flex');
        dndNextLevel.home.replaceClasses(toggle2014Text, dndNextLevel.themeOn, dndNextLevel.themeOff);
        dndNextLevel.home.replaceClasses(toggle2024Text, dndNextLevel.themeOff, dndNextLevel.themeOn);
    }
};

dndNextLevel.home.licenseToaster = () => {
    document.getElementById('apache-license').addEventListener('click', () => {
        const toasterContainer = document.getElementById('toasterContainer');
        const toaster = document.getElementById('toaster');
        const copyright = `Copyright ${new Date().getFullYear()} Arts-ICT`;

        toaster.textContent = copyright.trim();
        toasterContainer.classList.remove('hidden');
        toasterContainer.classList.add('block');

        setTimeout(() => {
            toasterContainer.classList.add('hidden');
            toasterContainer.classList.remove('block');
        }, 8500);
    });

}

dndNextLevel.home.replaceClasses = (element, oldClasses, newClasses) => {
    oldClasses.forEach((oldClass, index) => {
        element.classList.replace(oldClass, newClasses[index]);
    });
};


document.addEventListener('DOMContentLoaded', dndNextLevel.home.home);